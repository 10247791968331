<form novalidate class="form-container" [formGroup]="loginForm" (ngSubmit)="login()">
  <div class="column gap-lg">
    <!-- Email -->
    <div class="form-wrapper">
      <mat-label>{{ 'CommonTranslations.Email' | translate }}</mat-label>
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'LoginComponent.PlaceholderEmail' | translate }}"
          type="email"
          formControlName="email"
          name="email"
          required
          email
          data-cy="login-input-email"
        />
        @if (loginForm.get('email')?.hasError('email')) {
          <mat-error>
            {{ 'CommonTranslations.InvalidEmail' | translate }}
          </mat-error>
        }
        @if (loginForm.get('email')?.hasError('required')) {
          <mat-error>
            {{ 'LoginComponent.RequiredErrorEmail' | translate }}
          </mat-error>
        }
      </mat-form-field>
    </div>

    <!-- Password -->
    <div class="form-wrapper">
      <mat-label>{{ 'CommonTranslations.Password' | translate }}</mat-label>
      <mat-form-field>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          placeholder="{{ 'LoginComponent.PlaceholderPassword' | translate }}"
          type="password"
          formControlName="password"
          name="password"
          required
          data-cy="login-input-password"
        />
        <mat-icon matSuffix class="padding-xs grey-color-400 icon-md" (click)="togglePasswordVisibility($event)">
          {{ showPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </mat-form-field>
    </div>

    <div class="column vertical-center gap-ml">
      <!-- Forgot password -->
      <a class="text-btn text-btn--sm forgot-password" routerLink="/forgot-password" data-cy="forgot-password-button">
        {{ 'LoginComponent.ForgotPassword' | translate }}
      </a>

      <!-- Login button -->
      <button
        type="submit"
        mat-button
        class="btn btn--primary btn--lg btn--lg-mobile text-btn text-btn--white submit-button"
        [disabled]="loginForm.invalid"
        data-cy="login-button"
      >
        <ion-icon name="log-in-outline"></ion-icon>
        {{ 'CommonTranslations.Login' | translate }}
      </button>
    </div>
  </div>
</form>
